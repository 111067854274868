<template>
	<div class="my-orders-detail">
		<div class="order-status" v-if="orderDetail.order_st == 1"><span>待付款</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 2"><span>待发货</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 3"><span>待收货</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 4"><span>待评价</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 5"><span>已取消</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 6"><span>已退款</span></div>
		<div class="order-status" v-if="orderDetail.order_st == 7"><span>已完成</span></div>
		<div class="order-content">
			<div class="order-content-top">
				<div class="order-title">
					<span>订单信息</span>
				</div>
				<div class="order-time">
					<span>订单编号:{{orderDetail.order_num}}</span>
					<span>下单时间:{{orderDetail.create_time}}</span>
					<div v-if="orderDetail.order_st == 3">
						<span>快递单号:{{orderDetail.express_num}}</span>
						<br />
						<span>快递名称:{{orderDetail.express_type}}</span>
					</div>
				</div><!--  -->
			</div>
			<div class="order-goods">

				<div v-for="item in orderDetail.products" class="goods">
					<van-card :price="item.price" :desc="item.attr" :title="item.name" :thumb="imageUrl + item.image"
						:num="item.count">
					</van-card>
				</div>
			</div>
			<div class="order-price">
				<span class="lianxi" @click="goChatPage()">联系商家</span>
				<span>合计：<span style="color: #F43B39;">￥{{orderDetail.money}}</span></span>
			</div>
		</div>
		<div class="order-bottom"  v-if="orderDetail.order_st == 1">
			<div class="cancel-btn" @click="cancelOrder(orderDetail.id)"><span>取消订单</span></div>
			<div class="pay-btn" @click="payBtn(orderDetail.order_num)"><span>去支付</span></div>
		</div>
		<!-- <div class="order-bottom"  v-if="orderDetail.order_st == 2">
			
			<div class="pay-btn" @click="shipments()"><span>待发货</span></div>
		</div> -->
		<div class="order-bottom"  v-if="orderDetail.order_st == 3">
			<div class="pay-btn" @click="confirmRece(orderDetail.order_num)"><span>确认收货</span></div>
		</div>
		<div class="order-bottom"  v-if="orderDetail.order_st == 4">
			<div class="pay-btn" @click="evaluate(orderDetail.id)"><span>去评价</span></div>
		</div>
		<div class="order-bottom"  v-if="orderDetail.order_st == 7">
			<div class="pay-btn" @click="AnotherList()"><span>再来一单</span></div>
		</div>
	</div>
</template>

<script>
	import {
		getOrderDetail,
		orderCancel,
		getTN,
		orderCon
	} from '@/api/order'

	import {
		ref
	} from 'vue';
	import {
		useRoute
	} from "vue-router";
	import { Toast } from 'vant';
	export default {
		setup() {

		},
		data() {
			return {
				orderDetail: '',
				imageUrl: this.url.imageUrl,
				sid:'',
				store_name:'',
				type:'',
			}
		},

		mounted() {
			this.getOrderDetail()
		},
		methods: {
			getOrderDetail() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				params.id = this.$route.query.id
				getOrderDetail(params).then((res) => {
					console.log(res)
					this.sid = res.data.sid
					// console.log(this.sid)
					this.store_name = res.data.store_name
					// console.log(this.store_name)
					this.orderDetail = res.data
					this.$toast.clear()
				})
			},
			// 去评价
			evaluate(id){
				this.$router.push({
					path: '/evaluate',
					query: {
						id: id,
					}
				})
			},
			// 
			AnotherList(){
				this.$router.push({
					path: '/shopCart'
				})
			},
			// 确认收货
			confirmRece(order_num){
				console.log(order_num)
				let params = {
					order_num:order_num,
				}
				orderCon(params).then((res)=>{
					console.log(res)
					this.$toast(res.msg);
					setTimeout(()=>{
						this.$router.push({
							path: '/myOrders'
						})
					},1000)
					
				})
			},
			// 取消订单
			cancelOrder(id){
				console.log(id)
				let params = {
					id:id
				}
				orderCancel(params).then((res)=>{
					console.log(res)
					 this.$toast(res.msg);
					 this.$router.push({
					 	path: '/myOrders'
					 })
				})
			},
			payBtn(order_num){
				// console.log(order_num)
				this.getTN(order_num)
				let that = this
				getTN({
					'order_num': order_num
				}).then((res) => {
					console.log(res)
					upsdk.pluginReady(function() {
						upsdk.pay({
							tn: res.data.tn,
							success: function() {
								// 支付成功, 开发者执行后续操作。
								console.log('成功')
								that.$toast('支付成功')
								setTimeout(() => {
									that.$router.push({
										path: '/myOrders'
									})
								}, 1000)
							},
							fail: function(err) {
								// 支付失败, err.msg 是失败原因描述, 比如TN号不合法, 或者用户取消了交易 等等。
								that.$toast(err.msg)
							}
						});
					});
				})
			},
			getTN(order_num){
				console.log(order_num)
			},
			goChatPage() {
				// console.log(this.$route.query.id)
				let sid = this.sid
				// console.log(sid)
				this.$router.push({
					path: '/chatPage',
					query: {
						sid: sid,
						storeName: this.store_name
					}
				})
			}

		}
	}
</script>

<style lang="less" scoped>
	.my-orders-detail {
		padding: 15px;
		padding-bottom: 70px;

		.order-status {
			span {
				font-size: 18px;
			}
		}

		.order-content {
			padding: 15px 20px;
			margin-top: 20px;
			background-color: #FFFFFF;
			box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
			border-radius: 5px;

			.order-content-top {
				display: flex;
				width: 100%;
				justify-content: space-between;

				flex-direction: column;

				.order-title {
					span {
						font-size: 16px;
						color: #1A1A1A;
					}

				}

				.order-time {
					display: flex;

					flex-direction: column;

					margin-top: 10px;

					span {
						color: #666666;
						font-size: 12px;
						margin-bottom: 5px;
					}
				}
			}

			.order-goods {
				margin-top: 20px;

				.goods {
					margin-bottom: 10px;

					.van-card {
						padding: 8px 0;
						// box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);
						border-bottom: solid 1px #f2efef;
					}
				}
			}

			.order-price {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.lianxi {
					border: solid 1px #666666;
					font-size: 14px;
					padding: 3px 5px;
					color: #666666;
				}
			}
		}

		.order-bottom {
			position: fixed;
			bottom: 0;
			width: 100%;
			padding: 0 20px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			background-color: #FFFFFF;
			left: 0;
			box-sizing: border-box;
			box-shadow: 0px 17px 62px 0px rgba(0, 0, 0, 0.15);

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
				max-width: 100px;

				span {
					font-size: 14px;
				}
			}

			.cancel-btn {
				border: solid 1px #999999;
				color: #999999;
			}

			.pay-btn {
				border: solid 1px rgb(255, 209, 0);
				color: rgb(255, 209, 0);
				margin-left: 20px;
			}
		}
	}
</style>
